import React from "react"
import { Kind } from "../../common/kind"
import { getListPath } from "../../common/path"
import {
    localizeList,
    localizeListDescription,
    localizeNotYet,
} from "../i18n/kind"
import { jsonBreadcrumbList } from "../json/breadcrumb"
import { ContentHeader, ListPageList, Message } from "../views/content"
import { HeadLayout, Layout } from "../views/layout"

export type ListPageHeadLayoutProps = {
    isHome: boolean
    kind: Kind
}

export function ListPageHeadLayout({ isHome, kind }: ListPageHeadLayoutProps) {
    const path = getListPath({ kind })
    const title = localizeList(kind)
    const description = localizeListDescription(kind)
    const json = jsonBreadcrumbList({
        items: [
            {
                title,
                path,
            },
        ],
    })

    return (
        <HeadLayout
            title={title}
            description={description}
            path={path}
            isHome={isHome}
            json={json}
        />
    )
}

export type ListPageLayoutProps = {
    isHome: boolean
    kind: Kind
    items: React.ReactNode[]
}

export function ListPageLayout({ isHome, kind, items }: ListPageLayoutProps) {
    const title = localizeList(kind)

    return (
        <Layout isHome={isHome}>
            <ContentHeader>
                <h1>{title}</h1>
            </ContentHeader>
            {items.length <= 0 ? (
                <Message>{localizeNotYet(kind)}</Message>
            ) : (
                <ListPageList>{items}</ListPageList>
            )}
        </Layout>
    )
}
